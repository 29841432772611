import { z } from 'zod';
import { defineStore } from 'pinia';


export const useAddFormStore = defineStore('addFormStore', () => {
    // Props
    const addFormModal = ref({
        action: "",
        isOpen: false,
        formSection : {} as IFormSection,
        collectionName : "",
        fieldInfo : [] as z.infer<typeof FieldInfo>,
        CustomValidation :  (isValid: boolean) => { return isValid; },
        onItemAdded : () => {},
        onItemUpdate : () => {}
    });

    const fieldInfo = ref<z.infer<typeof FieldInfo>>([]);

    const SelectedFormSection = ref<IFormSection>();
    const EditListItem = ref<IQualificationListItem | IProfessionalMembershipsItem | IProfessionalWorkItem | IAdditionalDelegateItem>();
    
    // Getters
    const getAddFormModal = computed(
        () => {
            return addFormModal.value;
        }
    );
    // const FieldInfoList = computed({
    //     get () {
    //         return fieldInfo.value;
    //     },
    //     set (input: z.infer<typeof FieldInfo>) {
    //         fieldInfo.value = input;
    //     }
    // });

    //action
    function updateSelectedFormSection(section: IFormSection) {
        SelectedFormSection.value = section;
    }

    function openAddFormModal(action: string, 
                                formSection : IFormSection, 
                                collectionName : string, 
                                fieldInfo: z.infer<typeof FieldInfo>, 
                                CustomValidation : (isValid: boolean) => boolean,
                                onItemAdded : () => void,
                                onItemUpdate : () => void){
        addFormModal.value.action = action, 
        addFormModal.value.formSection = formSection;
        addFormModal.value.collectionName = collectionName;
        addFormModal.value.fieldInfo = fieldInfo;
        
        addFormModal.value.CustomValidation = CustomValidation
        addFormModal.value.onItemAdded = onItemAdded;
        addFormModal.value.onItemUpdate = onItemUpdate;
        /// MAKE SURE THIS IS ALWAY LAST>>>>
        addFormModal.value.isOpen = true;
    }

    // function openAddFormModal(action: string, section: IFormSection, fieldInfo: z.infer<typeof FieldInfo>, 
    //                                                                 listItem?: IQualificationListItem | IProfessionalMembershipsItem | IProfessionalWorkItem | IAdditionalDelegateItem,
    //                                                                 formSection? : IFormSection) {
    //     addFormModal.value.action = action, 
    //     addFormModal.value.formSection = formSection;
    //     SelectedFormSection.value = section;
    //     EditListItem.value = listItem;
    //     FieldInfoList.value = fieldInfo;
    //     addFormModal.value.isOpen = true;
    // }

    function closeAddFormModal() {
        addFormModal.value.isOpen = false;
    }

    return {
        SelectedFormSection,
        EditListItem,
        //FieldInfoList,
        getAddFormModal,
        updateSelectedFormSection,
        openAddFormModal,
        closeAddFormModal
    }
});