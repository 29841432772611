<template>
    <div class="gibs-page-tab-container">
        <div class="" :class="{ 'gibs-tabContent': shareProgStore.getSubProgrammeName == '' }">
            <div :class="{ 'gibs-newsletter-form-container': shareProgStore.getSubProgrammeName !== '' }">
                <div class="newsletter-form-wrapper">
                    <form @submit.prevent="submitForm">
                        <div class="gibs-signup-wrapper">
                            <div class="gibs-signup-containers">
                                <div class="gibs-newsletter-form-elements-container">
                                    <p class="gibs-share-prod-form-title" v-if="shareProgStore.getSubProgrammeName">Share
                                        with a colleague</p>
                                    <p class="gibs-share-prod-form-title" v-else>Share this with others</p>
                                    <form @submit.prevent="submitForm" class="form-container">
                                        <hr class="gibs-divider mb-[17px]" />
                                        <div class="gibs-share-prod-form-subtitle"
                                            v-if="shareProgStore.getSubProgrammeName">
                                            {{ shareProgStore.getSubProgrammeName }}
                                        </div>
                                        <hr class="gibs-divider my-[17px]" v-if="shareProgStore.getSubProgrammeName" />
                                        <!-- <GibsControlFormInput
                                                type="text"
                                                :inputId="`${idPrefix}-share-form-sender-name`"
                                                labelText="Your name"
                                                placeholderText="Your name"
                                                v-model="form.SenderName"
                                                @input="senderNameInput"
                                                :is-required="true"
                                                :validator="validation"
                                                :validator-rule-id="useNuxtApp().$EnumShareProgrammeFormValidatorId.SenderName"
                                                :show="!props.isLoggedIn"
                                                :class="{ 'hidden': !props.isLoggedIn }" 
                                                >
                                            </GibsControlFormInput>
                                            <GibsControlFormInput
                                                type="text" 
                                                :inputId="`${idPrefix}-share-form-sender-surname`" 
                                                labelText="Your surname"
                                                placeholderText="Your surname" 
                                                v-model="form.SenderSurname"
                                                @input="senderSurnameInput" 
                                                :is-required="true" 
                                                :validator="validation"
                                                :validator-rule-id="useNuxtApp().$EnumShareProgrammeFormValidatorId.SenderSurname" 
                                                :show="!props.isLoggedIn"
                                                :class="{ 'hidden': !props.isLoggedIn }" 
                                                >
                                            </GibsControlFormInput>    -->
                                        <GibsControlFormInput type="text"
                                            :inputId="`${idPrefix}-share-form-sender-name-tab`" labelText="Your name"
                                            placeholderText="Your name" v-model="form.SenderName" @input="senderNameInput"
                                            :is-required="true" :validator="validation"
                                            :validator-rule-id="useNuxtApp().$EnumShareProgrammeFormValidatorId.SenderName"
                                            :class="{ 'hidden': isLoggedIn }">
                                        </GibsControlFormInput>
                                        <GibsControlFormInput type="text"
                                            :inputId="`${idPrefix}-share-form-sender-surname-tab`" labelText="Your surname"
                                            placeholderText="Your surname" v-model="form.SenderSurname"
                                            @input="senderSurnameInput" :is-required="true" :validator="validation"
                                            :validator-rule-id="useNuxtApp().$EnumShareProgrammeFormValidatorId.SenderSurname"
                                            :class="{ 'hidden': isLoggedIn }">
                                        </GibsControlFormInput>
                                        <GibsControlFormInput type="text" :inputId="`${idPrefix}-share-form-recipient-name`"
                                            labelText="Recipient name" placeholderText="Recipient name"
                                            v-model="form.RecipientName" @input="firstNameInput" :is-required="true"
                                            :validator="validation"
                                            :validator-rule-id="useNuxtApp().$EnumShareProgrammeFormValidatorId.RecipientName">
                                        </GibsControlFormInput>
                                        <GibsControlFormInput type="text"
                                            :inputId="`${idPrefix}-share-form-recipient-surname`"
                                            labelText="Recipient surname" placeholderText="Recipient surname"
                                            v-model="form.RecipientSurname" @input="surnameInput" :is-required="true"
                                            :validator="validation"
                                            :validator-rule-id="useNuxtApp().$EnumShareProgrammeFormValidatorId.RecipientSurname">
                                        </GibsControlFormInput>
                                        <GibsControlFormInput type="email"
                                            :inputId="`${idPrefix}-share-form-recipient-email`" labelText="Recipient email"
                                            class="mb-4" placeholderText="Email address" v-model="form.Email"
                                            :is-required="true" :validator="validation"
                                            :validator-rule-id="useNuxtApp().$EnumShareProgrammeFormValidatorId.Email"
                                            @input="emailInput">
                                        </GibsControlFormInput>
                                        <div class="mt-4.5 max-w-full xs:w-full xs:max-w-full">
                                            <GibsControlPrimaryButton :primaryButtonSpec="shareProgrammeBtn"
                                                type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template> 
 
<script setup lang="ts">
import { z } from "zod";
import { useNotificationStore } from "~/stores/notification";
import { ServerValidationError } from "~/classes/validation-errors";
import { useProgShareStore } from '~/stores/programme-share';
import { crmContactResponseShareFormSchema } from '~/schemas/shareform-crm-contact';

const props = defineProps({
    "labelText": {
        type: String
    },
    "layoutView": {
        type: String
    },
    // "loggedinName": { 
    //     type: String
    // },
    // "isLoggedIn": {
    //     type: Boolean
    // },
    "progId": {
        type: String,
        default: ""
    },
    "idPrefix": {
        type: String,
        default: ""
    }
});

const store = useNotificationStore();
const app = useNuxtApp();
const shareProgStore = useProgShareStore();


const emit = defineEmits(["closeModal"]);

const form = ref<IProgrammeShareForm>({
    Email: "",
    RecipientName: "",
    RecipientSurname: "",
    SenderName: "",
    SenderSurname: "",
    ProgrammeID: ""
});

const { programmeShareFormValidation: programmeShareFormValidation } = useValidations();
const validation = programmeShareFormValidation(form.value);
//const signedInValidation = programmeSignedInShareFormValidation(form.value);

const shareProgrammeBtn = ref<IControlButton>({
    buttonElName: "gibs-primary-button",
    buttonText: "Submit",
    class: "btn-create-profile w-full xs:max-w-[132px] xs:w-full",
    sourceUrl: "",
    load: false
});
shareProgrammeBtn.value.disabled = !validation.isValid();

function firstNameInput(input: string) {
    form.value.RecipientName = input;
    validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.RecipientName, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
}

function surnameInput(input: string) {
    form.value.RecipientSurname = input;
    validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.RecipientSurname, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
}

function senderNameInput(input: string) {
    form.value.SenderName = input;
    validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderName, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
}

function senderSurnameInput(input: string) {
    form.value.SenderSurname = input;
    validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderSurname, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
}

function emailInput(input: string) {
    form.value.Email = input;
    validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.Email, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
    var name = form.value.SenderName;
    var surname = form.value.SenderSurname;

    // if (props.isLoggedIn) { 
    //     if (props.loggedinName || name == '') {
    //         name = props.loggedinName;
    //     }  
    // }
    if (isLoggedIn.value) {
        if (loggedInProfileName || name == '') {
            name = loggedInProfileName.value;
        }

        if (surname == '') {
            surname = loggedInProfileSurname.value;
        }
    }
    form.value.SenderName = name;
    validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderName, name);
    shareProgrammeBtn.value.disabled = !validation.isValid();

    form.value.SenderSurname = surname;
    validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderSurname, surname);
    shareProgrammeBtn.value.disabled = !validation.isValid();
}

const submitForm = async () => {
    try {
        // var name = form.value.SenderName; 
        // if (name == '' || props.loggedinName) {
        //     name = props.loggedinName;
        // }
        // else {
        //     if (loggedInProfileName.value) {
        //         name = loggedInProfileName.value;
        //     }
        // } 
        // var lastname = form.value.SenderSurname; 
        // if (lastname == '' || isLoggedIn.value) {
        //     lastname = loggedInProfileSurname.value; 
        // } 

        var programmeId = "";
        if (shareProgStore.getSubProgrammeId) {
            programmeId = shareProgStore.getSubProgrammeId;
        }
        else {
            programmeId = props.progId;
        }
        const formData: IProgrammeShareForm = {
            ProgrammeID: programmeId,
            RecipientName: form.value.RecipientName,
            RecipientSurname: form.value.RecipientSurname,
            Email: form.value.Email,
            SenderName: form.value.SenderName,
            SenderSurname: form.value.SenderSurname
        };

        sendDataToServer(formData);
    }
    catch (error) {
        let bodyText;

        if (error instanceof Error) {
            bodyText = error.message;
        }
        else {
            bodyText = '';
        }

        store.addNotification({
            type: "error",
            title: `There was an error while sharing the programme. Please try again.`,
            body: bodyText,
            date: new Date(),
            timeout: true,
            timer: 10000
        });
    }
};

const sendDataToServer = async (formData: IProgrammeShareForm) => {
    let notification: INotification;

    try {
        shareProgrammeBtn.value.disabled = true;
        shareProgrammeBtn.value.load = true;

        const { data: response, error, status } = await useLoaderFetch("/api/progformshare/postFormData", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: formData
        });

        shareProgrammeBtn.value.disabled = false;
        shareProgrammeBtn.value.load = false;

        
        const isSuccess: string = "success";
        const isError: string = "error";

        if (status.value === isSuccess) {
            //let shareResponse = SubscriptionResponse.parse(response.value);
            var successMessage = "You have successfully shared the programme with " + formData.RecipientName;
            // if (shareResponse.success == true) {
            notification = {
                type: "success",
                title: successMessage,
                body: "",
                date: new Date(),
                timeout: true,
                timer: 3000,
            };
            // Reset the form after successful submission
            form.value.Email = "";
            form.value.RecipientName = "";
            form.value.RecipientSurname = "";
            form.value.SenderName = "";
            form.value.SenderSurname = "";

            validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.Email, form.value.Email);
            validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.RecipientName, form.value.RecipientName);
            validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.RecipientSurname, form.value.RecipientSurname);
            validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderName, form.value.SenderName);
            validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderSurname, form.value.SenderSurname);

            shareProgrammeBtn.value.disabled = !validation.isValid();
            emit("closeModal");
            store.addNotification(notification);
            // } else if (shareResponse.success == false) {
            //     notification = {
            //         type: "error",
            //         title: "occurred.",
            //         body: shareResponse.error!,
            //         date: new Date(),
            //         timeout: true,
            //         timer: 10000
            //     };
            //     store.addNotification(notification);
            // }
        } else if (status.value === isError) {
            notification = {
                type: "error",
                title: "",
                body: `There was an error while sharing the programme. Please try again.`,
                date: new Date(),
                timeout: true,
                timer: 10000
            };
            store.addNotification(notification);
        }
    } catch (error) {

        let bodyText;

        if (error instanceof ServerValidationError) {
            bodyText = error.message;
        } else {
            bodyText = (error as Error).message;
        }

        notification = {
            type: "error",
            title: "",
            body: bodyText,
            date: new Date(),
            timeout: true,
            timer: 10000
        };
        store.addNotification(notification);
    }
};

let isLoggedIn = ref(false);
let isLoggedInStored = ref('false');
let loggedInProfileName = ref("");
let loggedInProfileSurname = ref("");
let isShareFormModalOpen = ref(false);
let parsedData = ref();

// watchEffect(async () => {
//     const userSessionData: z.infer<typeof GibsUserSession> = await $fetch('/api/auth/user');
//     parsedData.value = GibsUserSession.safeParse(userSessionData); 
// });  

// watch(() => parsedData.value, (newVal) => {
//     if (newVal.contact != null) {
//         isLoggedIn.value = true;
//         loggedInProfileName.value = newVal.contact.firstname;
//         loggedInProfileSurname.value = newVal.contact.lastname;
//     }
//     else {
//         isLoggedIn.value = false;
//         form.value.SenderName = "";
//         form.value.SenderSurname = "";
//     }

//     // Update the validation and button state
//     validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderName, form.value.SenderName);
//     validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderSurname, form.value.SenderSurname);
//     shareProgrammeBtn.value.disabled = !validation.isValid();
// });

watch(() => shareProgStore.getProgShareModal, async (newVal) => {
    isShareFormModalOpen.value = newVal;
    if (!isShareFormModalOpen.value) {
        form.value.Email = "";
        form.value.RecipientName = "";
        form.value.RecipientSurname = "";
        form.value.SenderName = "";
        form.value.SenderSurname = "";

        validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.Email, form.value.Email);
        validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.RecipientName, form.value.RecipientName);
        validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.RecipientSurname, form.value.RecipientSurname);
        validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderName, form.value.SenderName);
        validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderSurname, form.value.SenderSurname);
        shareProgrammeBtn.value.disabled = !validation.isValid();
    } 
    if (process.client) {
        isLoggedInStored.value = process.client ? localStorage.getItem('Is_Logged_In') ?? 'false' : 'false';
        if (isLoggedInStored.value == 'true') {
            isLoggedIn.value = true;
            loggedInProfileName.value = process.client ? localStorage.getItem('Logged_In_Name') ?? '' : '';
            loggedInProfileSurname.value = process.client ? localStorage.getItem('Logged_In_Surname') ?? '' : '';
        }
        else if (isLoggedInStored.value == 'false') {
            isLoggedIn.value = false;
        } 
        //await fetchCrmData(); 
    }
});

const defaultCrmContactResponse: Partial<z.infer<typeof CrmContactResponse>> = {
    "contactId": Guid.Empty
};

const fetchCrmData = async () => {
    await Promise.all([
        $fetch('/api/profile/contact')
            .then(response => {
                //let crmContact: z.infer<typeof CrmContactResponse> = defaultCrmContactResponse;

                if (response) {
                    // var schemaData = crmContactResponseShareFormSchema.parse(response);
                    // console.log('schemaData', schemaData)
                    // crmContact = response ?
                    //         parseJSONObjectV2(CrmContactResponse, response.toString()) as z.infer<typeof CrmContactResponse> :
                    //         defaultCrmContactResponse; 
                    const responseObject = JSON.parse(response);
                    parsedData.value = responseObject;
                    if (responseObject != null) {
                        isLoggedIn.value = true;
                        if (responseObject.firstname != null) {
                            loggedInProfileName.value = responseObject.firstname;
                        }
                        if (responseObject.lastname != null) {
                            loggedInProfileSurname.value = responseObject.lastname;
                        }
                    }
                }

                if (parsedData.value.contactID === Guid.Empty) {
                    console.error('Error retrieving CRM Contact Information: ' + JSON.stringify(response, null, 2));
                }
            })
            .catch(error => {
                console.error('Error parsing data: ' + JSON.stringify(error, null, 2));
            })
    ])
}

// onBeforeMount(async () => {
//     await fetchCrmData();
// });

isLoggedInStored.value = process.client ? localStorage.getItem('Is_Logged_In') ?? 'false' : 'false';
if (isLoggedInStored.value == 'true') {
    isLoggedIn.value = true;
}
else if (isLoggedInStored.value == 'false') {
    isLoggedIn.value = false;
}
loggedInProfileName.value = process.client ? localStorage.getItem('Logged_In_Name') ?? '' : '';
loggedInProfileSurname.value = process.client ? localStorage.getItem('Logged_In_Surname') ?? '' : '';

// watch(() => parsedData.value, (newVal) => {
//     isLoggedIn.value = newVal.data.isLoggedIn;
//     loggedInProfileName.value = newVal.data.userName;
//     loggedInProfileSurname.value = newVal.data.lastname;

//     // If the user logs out, reset form values
//     if (!isLoggedIn.value) {
//         form.value.SenderName = "";
//         form.value.SenderSurname = "";
//     }

//     // Update the validation and button state
//     validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderName, form.value.SenderName);
//     validation.setValidationRuleSourceValue(app.$EnumShareProgrammeFormValidatorId.SenderSurname, form.value.SenderSurname);
//     shareProgrammeBtn.value.disabled = !validation.isValid();
// });

</script>   