import { defineStore } from 'pinia';

export const useFormOnlinePaymentStore = defineStore('useFormOnlinePaymentStore', () => {
    var onlinePaymentModal = ref<boolean>(false);
    var continueToPayGateClicked = ref<boolean>(false); 
    var showCancelLoader = ref<boolean>(false); 
    var enrolmentId = ref<IAbortApplication>(""); 

    const getFormPaymentModal = computed(
        () => {
            return onlinePaymentModal.value;
        }
    );

    const getContinueBtnClicked = computed(
        () => {
            return continueToPayGateClicked.value;
        }
    );

    function setContinueBtnClicked(abortBtnClicked: boolean) {
        continueToPayGateClicked.value = abortBtnClicked;
    } 

    function isOpenOnlinePaymentModal() {
        onlinePaymentModal.value = true;
    }
    function isClosedFormOnlinePaymentModal() {
        onlinePaymentModal.value = false;
    } 

    function setEnrolmentId(id: IAbortApplication) {
        enrolmentId.value = id;
    } 

    const getRedirectToPaymentLoader = computed(
        () => {
            return showCancelLoader.value;
        }
    );

    function setRedirectToPaymentLoader(visibility: boolean) {
        showCancelLoader.value = visibility;
    }   

    const getEnrolmentId = computed(() => enrolmentId.value);

    return {
        getFormPaymentModal: getFormPaymentModal,
        isOpenOnlinePaymentModal: isOpenOnlinePaymentModal,
        isClosedFormOnlinePaymentModal: isClosedFormOnlinePaymentModal, 
        getContinueBtnClicked: getContinueBtnClicked,  
        getRedirectToPaymentLoader: getRedirectToPaymentLoader, 
        setRedirectToPaymentLoader: setRedirectToPaymentLoader, 
        setContinueBtnClicked,
        getEnrolmentId: getEnrolmentId, 
        setEnrolmentId
    }
});