import { defineStore } from 'pinia'

export const useRouteHistoryStore = defineStore({
    id: 'routeHistory',
    state: () => ({
        history: [] as string[],
    }),
    actions: {
        addRoute(route: string) {
        this.history.push(route)
        },
        getLatestHistory() {
            return this.history.slice(Math.max(this.history.length - 10, 0));
        },
    },
})
